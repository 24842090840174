.hexagone {

  @media (min-width: 1097px) {
    .hexagone-parentItem {
      .hexagone-item {
        width: 320px;
        height: 320px;
        margin-top: -160px;
        .hexagone-subItem {
          width: 310px;
          height: 310px;
          .hexagone-amount {
            font-size: 85px;
            line-height: 76px;
          }
          .hexagone-title {
            font-size: 35px;
            line-height: 35px;
          }
        }
      }
      .hexagone-intro-section {
        width: 360px;
        .hexagone-intro {
          font-size: 26px;
          line-height: 35px;
        }
      }
    }
  }
  @media (min-width: 745px) and (max-width: 1096px) {
    .hexagone-parentItem {
      .hexagone-item {
        width: 230px;
        height: 230px;
        margin-top: -115px;
        .hexagone-subItem {
          width: 220px;
          height: 220px;
          .hexagone-amount {
            font-size: 50px;
            line-height: 50px;
          }
          .hexagone-title {
            font-size: 25px;
            line-height: 35px;
          }
        }
      }
      .hexagone-intro-section {
        width: 236px;
        .hexagone-intro {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }

  }
  @media (min-width: 558px) and (max-width: 744px) {
    .hexagone-parentItem {
      .hexagone-item {
        width: 134px;
        height: 134px;
        margin-top: -67px;
        .hexagone-subItem {
          width: 128px;
          height: 128px;
          .hexagone-amount {
            font-size: 30px;
            line-height: 20px;
          }
          .hexagone-title {
            font-size: 15px;
            line-height: 20px;
          }
        }
      }
      .hexagone-intro-section {
        width: 177px;
        .hexagone-intro {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }
  @media (max-width: 557px) {
    flex-flow: column;
    align-items: center;
    margin-bottom: -190px;
    .hexagone-parentItem {
      margin-top: -160px;
      margin-bottom: 260px;
      .hexagone-item {
        width: 320px;
        height: 320px;
        .hexagone-subItem {
          width: 310px;
          height: 310px;
          .hexagone-amount {
            font-size: 85px;
            line-height: 76px;
          }
          .hexagone-title {
            font-size: 35px;
            line-height: 35px;
          }
        }
      }
      .hexagone-intro-section {
        width: 360px;
        .hexagone-intro {
          font-size: 26px;
          line-height: 35px;
        }
      }
    }
  }
  @media (max-width: 430px) {
    .hexagone-parentItem {
      margin-top: -100px;
      margin-bottom: 175px;
      .hexagone-item {
        width: 210px;
        height: 210px;
        .hexagone-subItem {
          width: 200px;
          height: 200px;
          .hexagone-amount {
            font-size: 60px;
            line-height: 60px;
          }
          .hexagone-title {
            font-size: 28px;
            line-height: 20px;
          }
        }
      }
      .hexagone-intro-section {
        width: 300px;
        .hexagone-intro {
          font-size: 19px;
          line-height: 25px;
        }
      }
    }
  }
}