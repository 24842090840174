.grid-first {
  grid-template-columns: repeat(3, 1fr);
  padding: 55px 80px 30px 70px;
}
.grid-sec {
  grid-template-columns: repeat(3, 1fr);
  padding: 0px 80px 30px 70px;
  .colspan-2 {
    grid-column: span 2/span 2;
  }
}
.grid-third, .grid-third-how-it-works {
  grid-template-columns: repeat(3, 1fr);
  .colspan-2 {
    grid-column: span 2/span 2;
    padding: 30px 80px 225px 70px;
  }
  .you-can-help {
    padding: 30px 80px 225px 70px;
  }
  .col-sec {
    padding: 30px 10px 225px 70px;
  }
  .col-third {
    padding: 30px 80px 225px 10px;
  }
}

@media (min-width: 1250px) and (max-width: 1280px) {
  .grid-third-how-it-works {
    grid-template-columns: repeat(3, 1fr);
    .you-can-help {
      padding: 30px 80px 225px 70px;
    }
    .col-sec {
      padding: 30px 10px 225px 70px;
    }
    .col-third {
      padding: 30px 80px 225px 10px;
    }
  }
}
@media (max-width: 1249px) {
  .grid-third-how-it-works {
    grid-template-columns: repeat(2, 1fr);
    .you-can-help {
      grid-column: span 2/span 2;
      padding: 30px 80px 225px 70px;
    }
    .col-sec {
      padding: 30px 10px 225px 70px;
    }
    .col-third {
      padding: 30px 80px 225px 10px;
    }
  }
}
@media (max-width: 1054px) {
  .grid-first {
    grid-template-columns: repeat(2, 1fr);
    padding: 55px 50px 30px 50px;
  }
  .grid-sec {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px 50px 30px 50px;
    .colspan-2 {
      grid-column: span 1/span 1;
    }
  }
  .grid-third ,.grid-third-how-it-works {
    .colspan-2 {
      padding: 30px 50px 200px 50px;
    }
    .you-can-help {
      padding: 30px 50px 120px 50px;
    }
    .col-sec {
      padding: 30px 10px 165px 70px;
    }
    .col-third {
      padding: 30px 50px 165px 10px;
    }
  }
}
@media (max-width: 720px) {
  .grid-sec {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 50px 30px 50px;
    .colspan-2 {
      grid-column: span 1/span 1;
    }
  }
  .grid-third{
    grid-template-columns: repeat(1, 1fr);
    .colspan-2 {
      grid-column: span 1/span 1;
      padding: 30px 50px 200px 50px;
    }
    .you-can-help {
      padding: 30px 50px 120px 50px;
    }
  }
  .grid-third-how-it-works {
    grid-template-columns: repeat(1, 1fr);
    .you-can-help {
      grid-column: span 1/span 1;
      padding: 30px 50px 120px 50px;
    }
    .col-sec {
      padding: 30px 50px 0px 50px;
    }
    .col-third {
      padding: 30px 50px 200px 50px;
    }
  }

}
@media (max-width: 650px) {
  .grid-first {
    grid-template-columns: repeat(1, 1fr);
    padding: 55px 50px 30px 50px;
  }
}
@media (max-width: 425px) {
  .grid-first {
    padding: 55px 20px 30px 20px;
  }
  .grid-sec {
    padding: 0px 20px 30px 20px;
  }
  .grid-third, .grid-third-how-it-works {
    .colspan-2 {
      padding: 30px 20px 170px 20px;
    }
    .you-can-help {
      padding: 30px 20px 50px 20px;
    }
    .col-sec {
      padding: 30px 20px 0px 20px;
    }
    .col-third {
      padding: 30px 20px 200px 20px;
    }
  }
}