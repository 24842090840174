.sub-information{
  .chakra-wrap__list {
    margin: 0px !important;
    .chakra-wrap__listitem {
      margin: 0px !important;
    }
  }
  
  // @media (min-width: 1300px) {
    .carousel {
      width: 100%;
      max-width: 825px;
    }
    .product-option {
      width: 25%;
      padding: 40px;
    }
    .product {
      justify-content: end;
      height: 100%;
      width: 75%;
      .product-img {
        width: 100%;
        max-width: 825px;
        object-fit: cover;
      }
    }
    .react-multi-carousel-list {
      max-width: 100%;
    }
    .product-description {
      columns: auto 3;
      column-fill: auto;
      column-gap: 100px;
      font-size: 20px;
      padding: 50px;
    }
  // }
  @media (min-width: 838px) and (max-width: 1299px) {
    .product-option {
      width: 50%;
    }
    .product {
      height: 100%;
      width: 50%;
      .product-img {
        max-height: 65%;
        height: auto;
      }
    }
    .product-description {
      columns: auto 3;
      column-fill: auto;
      column-gap: 100px;
    }
  }
  @media (max-width: 837px) {
    .product-option {
      width: 100%;
      margin-top: 557px;
      .chakra-select__wrapper {
        max-width: 333px;
      }
    }
    .product {
      justify-content: center;
      position: absolute;
      height: 40%;
      width: 100%;
      padding: 40px;
    }
    .product-description {
      columns: auto 2;
      column-fill: auto;
      column-gap: 100px;
    }
  }
  @media (max-width: 650px) {
    .product-description {
      columns: auto 1;
      column-fill: auto;
      column-gap: 100px;
      padding: 40px;
    }
  }
  @media (max-width: 510px) {
    .product {
      max-height: 40%;
      height: auto;
      .product-img {
        max-height: 80%;
        height: auto;
      }
    }
    .product-option {
      margin-top: 398px;
      .chakra-select__wrapper {
        max-width: 100%;
      }
    }
  }
  @media (max-width: 425px) {
    .product, .product-option, .product-description {
      padding: 20px;
    }
  }
}