.invitation {
  object-fit: cover;  

  @media (min-width: 818px){
    min-height: 140px;
  }
  @media (min-width: 635px) and (max-width: 817px) {
    min-height: 95px;
    
  }
  @media (min-width: 490px) and (max-width: 634px) {
    min-height: 110px;
  }
  @media (max-width: 489px) {
    min-height: 110px;
   
  }
}