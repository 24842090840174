

.root {
    font-family: 'Lato';
     /* position: relative; */
     color: #fff;
     background: none;
     min-width: '100%';
     padding-bottom: 2rem;
   }
   
   .content {
     width: 100vw;
     min-height: 100vh;
     padding: 0 1.5em;
     box-sizing: border-box;
     z-index: 1;
     display: flex;
     justify-content: center;
     align-items: center;
   }
   
   .image {
     position: absolute;
     left: 0;
     top: 0;
     overflow: hidden;
     display: block;
     width: 100vw;
     height: 100vh;
     object-fit: cover;
     transition: opacity 1s ease;
     opacity: 0;
   }
   
   .active {
       opacity: 1;
   }
   
   .title {
     text-align: center;
     text-transform: uppercase;
     letter-spacing: 2px;
     position: relative;
     font-weight: 300;
     text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
     margin: 0;
     padding: 0;
   
   }
   .carousel-control-next, .carousel-control-prev {
     width: 60px !important;
   }   
   .carousel-indicators {
     display: none !important;
   }

   #carousel-section {
     height: 100vh;
     .carousel-audio-text {
       margin: 0px 8px;
     }
     .carousel-header {
       height: 100vh;
     }
     @media (max-width: 430px) {
      height: 530px;
      .carousel-header {
        height: 520px;
      }
     }
   }
  @media (min-width: 1097px) {
    #carousel-section {
      height: calc(100vh - 17px);
    }
  } 
  @media (min-width: 745px) and (max-width: 1096px) {
    #carousel-section {
      height: calc(100vh - 275px);
      .carousel-audio-text {
        top: 510px !important;
      }
      .carousel-caption {
        top: 319px !important;
      }
    }
  }
  @media (min-width: 558px) and (max-width: 744px) {
    #carousel-section {
      height: calc(100vh - 205px);
      .carousel-audio-text {
        top: 510px !important;
      }
      .carousel-caption {
        top: 319px !important;
      }
    }
  }
  @media (min-width: 431px) and (max-width: 557px) {
    #carousel-section {
      height: calc(100vh - 17px);
      .carousel-audio-text {
        top: 510px !important;
      }
      .carousel-caption {
        top: 319px !important;
      }
    }
  }

  @media screen and (orientation:landscape)
    and (max-height: 480px) {
    #carousel-section {
      height: calc(100vh - 5px);
      .carousel-audio-text {
        top: 240px !important;
      }
      .carousel-caption {
        top: 70px !important;
      }
    }
  }
  @media (max-width: 1097px) and (max-height: 828px) and (min-height: 481px) {
    #carousel-section {
      height: calc(100vh - 5px);
      .carousel-audio-text {
        top: 295px !important;
      }
      .carousel-caption {
        top: 120px !important;
      }
    }
  }