*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}



a{
	text-decoration: none;
	color: #eee;
}



.container{
	max-width: 120rem;
	width: 90%;
	margin: 0 auto;
}

.menu-toggle{
	position: fixed;
	right: 2.5rem;
	color: #eeeeee;
	cursor: pointer;
	z-index: 1000;
	display: none;
}

nav{
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-transform: uppercase;
	font-size: 1.6rem;
}




nav ul{
	display: flex;
}

 li{
	list-style: none;
	transform: translateX(-100rem);
	animation: slideIn .5s forwards;
}

nav ul li:nth-child(1){
	animation-delay: 0s;
}

nav ul li:nth-child(2){
	animation-delay: .5s;
}

nav ul li:nth-child(3){
	animation-delay: 1s;
}

nav ul li:nth-child(4){
	animation-delay: 1.5s;
}

nav ul li a{
	margin: 0 3rem;
	position: relative;
	letter-spacing: 2px;
}

nav ul li a:last-child{
	margin-right: 0;
}

nav ul li a::before,
nav ul li a::after{
	content: '';
	position: absolute;
	width: 100%;
	height: 2px;
	background-color: crimson;
	left: 0;
	transform: scaleX(0);
	transition: all .5s;
}

nav ul li a::before{
	top: 0;
	transform-origin: left;
}

nav ul li a::after{
	bottom: 0;
	transform-origin: right;
}

.overlay{
	background-color: rgba(0,0,0,.95);
	position: fixed;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	transition: opacity 650ms;
	transform: scale(0);
	opacity: 0;
  display: none;
}

nav ul li a:hover::before,
nav ul li a:hover::after{
	transform: scaleX(1);
}

@keyframes slideIn {
	from{

	}
	to{
		transform: translateX(0);
	}
}

@media screen and (max-width: 700px){

	.menu-toggle{
		display: block;
	}

	nav{
		padding-top: 0;
		display: none;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		text-align: center;
	}

	nav ul{
		flex-direction: column;
	}

	nav ul li{
	}

	nav ul li a{
		margin: 0;
	}

	.brand{
	}
  
  .overlay.menu-open,
  nav.menu-open{
	  display: flex;
	  transform: scale(1);
	  opacity: 1;
  }
  
}
.menu {
	 
	  transition: transform 0.8s cubic-bezier(0.25, 0.05, 0.1, 0.94);
	}
.is-non-active {
	  /* transform: translateX(130px); */
	  transform: translateX(140px);
		margin-left: -3px;
		/* transition: transform 0.8s cubic-bezier(0.25, 0.05, 0.1, 0.94); */
	  /* transition-delay: 2000ms; */
}
.is-non-active-scrolling {
	/* transform: translateX(45px);
	margin-left: -45px; */
	transform: translateX(110px);
	margin-left: -3px;
}
.is-active, .is-active-scrolling {
	/* transform: translateX(-100px); */
	/* margin-left: -133px; */
	/* transform: translateX(10px); */
	/* transition: transform 0.8s cubic-bezier(0.25, 0.05, 0.1, 0.94); */
}

.header-bar {
	padding: 2px 20px;
	.header-menu-frame {
		img {
			width: 39.6px;
		}
		p {
			font-size: 14px;
			padding: 8px;
		}
	}
	.header-menu {
		img {
			width: 26.8px;
		}
		p {
			font-size: 12px;
			padding-left: 3px;
			margin-right: 6px;
		}
	}
	.header-menu-closed {
		img {
			width: 0px;
		}
		p {
			font-size: 0px;
			padding-left: 0px;
			margin-right: 0px;
		}
	}
	.header-logo {
		width: 115px;
		transition: all 0.5s;
	}
}

.header-bar-isTop {
	padding: 8px 20px;
	.header-menu-frame {
		img {
			width: 53.9px;
		}
		p {
			font-size: 20px;
			padding: 12px;
		}
	}
	.header-menu {
		img {
			width: 35.7px;
		}
		p {
			font-size: 14px;
			padding-left: 5px;
			margin-right: 10px;
		}
	}
	.header-menu-closed {
		img {
			width: 0px;
		}
		p {
			font-size: 0px;
			padding-left: 0px;
			margin-right: 0px;
		}
	}
	.header-logo {
		width: 167.8px;
		transition: all 0.5s;
	}
}

@media (min-width: 1159px) and (max-width: 1385px) {
	.header-bar-isTop {
		padding-top: 3px;
		padding-bottom: 3px;
		.header-menu-frame {
			img {
				width: 39.6px;
			}
			p {
				font-size: 14px;
				padding: 8px;
			}
		}
		.header-menu {
			img {
				width: 26.8px;
			}
			p {
				font-size: 12px;
				padding-left: 3px;
				margin-right: 6px;
			}
		}
		.header-logo {
			width: 115px;
		}
	}
	.is-non-active {
		transform: translateX(110px);
		margin-left: -3px;
	}
}

@media (max-width: 1158px) {
	.is-non-active {
		transform: translateY(2px);
		// margin-top: -30px;
		flex-flow: column;
		margin-left: -10px;
		padding: 20px;
		width: 182.72px;
		background-color: white;
		top: 68px;
	}
	.is-non-active-scrolling {
		transform: translateY(2px);
		// margin-top: 10px;
		flex-flow: column;
		margin-left: -10px;
		padding: 16px;
		width: 154.69px;
		background-color: white;
		top: 39px;
	}
	.is-active {
		flex-flow: column;
		margin-left: -10px;
		padding: 20px;
		width: 0px;
		width: 182.72px;
		transition: all 0.5s;
		top: 69px;
	}
	.is-active-scrolling {
		flex-flow: column;
		margin-left: -10px;
		padding: 20px;
		width: 0px;
		width: 154.69px;
		transition: all 0.5s;
		top: 40px;
	}
	.header-menu {
		padding-bottom: 10px;
		img {
			width: 26.8px;
		}
		p {
			font-size: 12px;
			padding-left: 3px;
			margin-right: 6px;
		}
	}
}
@media (min-width: 425px) and (max-width: 530px) {
	.is-non-active {
		transform: translateY(2px);
		flex-flow: column;
		margin-left: -10px;
		padding: 16px;
		width: 154.69px;
		background-color: white;
		top: 51px;
	}
	.is-active {
		flex-flow: column;
		margin-left: -10px;
		padding: 16px;
		width: 0px;
		width: 154.39px;
		transition: all 0.5s;
		top: 52px;
	}
	.is-non-active-scrolling {
		transform: translateY(10px);
		// margin-top: 10px;
		flex-flow: column;
		margin-left: -10px;
		padding: 16px;
		width: 154.69px;
		background-color: white;
		top: 39px;
	}
	.is-active-scrolling {
		flex-flow: column;
		margin-left: -10px;
		padding: 20px;
		width: 0px;
		width: 154.69px;
		transition: all 0.5s;
		top: 39px;
	}
	.header-bar-isTop {
		padding: 4px 20px;
		.header-menu-frame {
			img {
				width: 39.6px;
			}
			p {
				font-size: 14px;
				padding: 8px;
			}
		}
		.header-menu {
			padding-bottom: 10px;
			img {
				width: 26.8px;
			}
			p {
				font-size: 12px;
				padding-left: 3px;
				margin-right: 6px;
			}
		}
		.header-menu-closed {
			img {
				width: 0px;
			}
			p {
				font-size: 0px;
				padding-left: 0px;
				margin-right: 0px;
			}
		}
		.header-logo {
			width: 115px;
		}
	}

}

@media (max-width: 424px) {
	.is-non-active {
		transform: translateY(2px);
		flex-flow: column;
		margin-left: -10px;
		padding: 16px;
		width: 154.69px;
		background-color: white;
		top: 51px;
	}
	.is-active {
		flex-flow: column;
		margin-left: -10px;
		padding: 16px;
		width: 0px;
		width: 154.39px;
		transition: all 0.5s;
		top: 52px;
	}
	.is-non-active-scrolling {
		transform: translateY(10px);
		// margin-top: 10px;
		flex-flow: column;
		margin-left: -10px;
		padding: 16px;
		width: 154.69px;
		background-color: white;
		top: 39px;
	}
	.is-active-scrolling {
		flex-flow: column;
		margin-left: -10px;
		padding: 20px;
		width: 0px;
		width: 154.69px;
		transition: all 0.5s;
		top: 39px;
	}
	.header-bar-isTop {
		padding: 4px 20px;
		.header-menu-frame {
			img {
				width: 39.6px;
			}
			p {
				font-size: 14px;
				padding: 8px;
			}
		}
		.header-menu {
			padding-bottom: 10px;
			img {
				width: 26.8px;
			}
			p {
				font-size: 12px;
				padding-left: 3px;
				margin-right: 6px;
			}
		}
		.header-menu-closed {
			img {
				width: 0px;
			}
			p {
				font-size: 0px;
				padding-left: 0px;
				margin-right: 0px;
			}
		}
		.header-logo {
			margin-left: 25px !important;
			width: 115px;
		}
	}
	.Cart-Text {
		display: none;
	}
	.header-bar {
		.header-logo {
			margin-left: 25px !important;
		}
	}
}