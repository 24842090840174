.testimonials {
  .sub-testimonials {
    column-fill: auto;
    column-gap: 100px;
  }
  .testimonial-content {
    margin-bottom: 400px;
  }
  .more-stories-showed {
    padding: 10px 80px 25px 70px;
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  .more-stories-showed-mobile {
    display: none;
  }
  .more-stories {
    padding: 0px 80px;
  }
  @media (max-width: 1039px) {
    .testimonial-content {
      margin-bottom: 180px;
    } 
    .more-stories-showed {
      display: none;
    }
    .more-stories-showed-mobile {
      display: unset;
    }
  }
  @media (min-width: 1015px) {
    .sub-testimonials {
      columns: auto 3;
    }
  }
  @media (max-width: 1014px) {
    .sub-testimonials {
      columns: auto 2;
    }
  }
  @media (min-width: 470px) and (max-width: 768px) {
    .testimonial-content {
      margin-bottom: 50px;
    }
    #sub-testimonials {
      padding-left: 40px;
      padding-right: 40px;
      .sub-testimonials {
        columns: auto 1;
      } 
    }
    .more-stories {
      padding: 0px 40px;
    }
  }
  @media (max-width: 469px) {
    .testimonial-content {
      margin-bottom: 30px;
    }
    #sub-testimonials {
      padding-left: 20px;
      padding-right: 20px;
      .sub-testimonials {
        columns: auto 1;
        font-size: 15px;
      } 
    }
    .more-stories {
      padding: 0px 20px;
    }
  }
}