.newsletter-first-grid {
  grid-template-columns: 1fr 1fr;
}
.newsletter-sec-grid {
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1200px) {
  .newsletter-first-grid {
    grid-template-columns: auto;
    justify-content: center;
    div {
      max-width: 610px;

      &:nth-child(1) {
        order: 1;
      }
      &:nth-child(2) {
        order: 0;
        display: flex;
        justify-content: center;
        margin-bottom: 70px;
      }
    }
  }
  .newsletter-sec-grid {
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
  }
}

@media (max-width: 768px){
  .newsletter-first-grid {
    div {
      max-width: 500px;

      &:nth-child(1) {
        p {
          padding-inline-start: 0px;
          padding-inline-end: 0px;
        }
      }
      &:nth-child(2) {
        img {
          max-width: 500px;
        }
      }

    }
  }
  .newsletter-sec-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 538px){
  .newsletter-first-grid {
    div {
      max-width: 320px;

      &:nth-child(1) {
        p {
          padding-inline-start: 0px;
          padding-inline-end: 0px;
        }
      }
      &:nth-child(2) {
        img {
          max-width: 320px;
        }
      }

    }
  }
}