.footer-bar {
  .footer-octagon {
    top: -74px;
    row-gap: 35px;
    img {
      width: 146px;
      height: 147px;
    }
  }
  .footer-top-image {
    img {
      height: 50px;
    }
  }
  .footer-image {
  }
  .footer-logo {

  }

  @media (min-width: 1130px) {
    .footer-image {
      img {
        height: 85px;
      }
    }
    .footer-logo {
      img {
        width: 130px;
      }
    }
  }

  @media (min-width: 935px) and (max-width: 1129px) {
    .footer-image {
      img {
        height: 60px;
      }
    }
    .footer-logo {
      img {
        width: 90px;
      }
    }
  }

  @media (min-width: 676px) and (max-width: 934px) {
    .footer-menu-section {
      font-size: 15px;
      line-height: 25px;
    }
    .footer-contents {
      padding-right: 20px;
      padding-left: 20px;
      .footer-image {
        img {
          height: 60px;
        }
      }
      .footer-logo {
        img {
          width: 90px;
        }
      }
    }
  }

  @media (min-width: 494px) and (max-width: 675px) {
    .footer-octagon {
      top: -58px;
      row-gap: 35px;
      img {
        width: 115px;
        height: 115px;
      }
    }
    .footer-menu-section {
      font-size: 15px;
      line-height: 25px;
    }
    .footer-contents {
      padding-right: 20px;
      padding-left: 20px;
      .footer-image {
        img {
          height: 40px;
        }
      }
      .footer-logo {
        img {
          width: 70px;
        }
      }
    }
  }
  
  @media (min-width: 326px) and (max-width: 493px) {
    .footer-octagon {
      top: -58px;
      row-gap: 35px;
      img {
        width: 115px;
        height: 115px;
      }
    }
    .footer-menu-section {
      font-size: 15px;
      line-height: 25px;
    }
    .footer-contents {
      padding-right: 20px;
      padding-left: 20px;
      .footer-image {
        gap: 3px;
        img {
          height: 25px;
        }
      }
      .footer-logo {
        img {
          width: 50px;
        }
      }
    }    
  }
  @media (max-width: 325px) {
      .footer-octagon {
        top: -40px;
        row-gap: 10px;
        img {
          width: 80px;
          height: 80px;
        }
      }
      .footer-menu-section {
        font-size: 15px;
        line-height: 25px;
      }
      .footer-contents {
        padding-right: 20px;
        padding-left: 20px;
        .footer-image {
          gap: 3px;
          img {
            height: 25px;
          }
        }
        .footer-logo {
          img {
            width: 50px;
          }
        }
      }
  }
}