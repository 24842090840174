#donate-btn {
  right: 0px;
  .donate-btn {
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    opacity: 0.9;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 25px 5px;
    border-radius: 10px 0px 0px 10px;
    transition: all 0.5s;

    .donate-img {
      transition: all 0.5s;
      background-image: url('../../../assests/smile_black.png');
      width: 50px;
      height: 50px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    .donate-text {
      transform: rotate(180deg) translate(2px, 0px);
      writing-mode: vertical-lr;
      margin-top: 25px;
      font-size: 24px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.9);
      font-family: 'Roboto';
      transition: all 0.5s;
    }

    &:hover {
      background-color: green;
      .donate-img {
        background-image: url('../../../assests/smile_white.png');
      }
      .donate-text {
        color: rgba(255, 255, 255, 0.9);
      }
    }
  }
}