.video-carousel {
  width: 100%;
  margin-top: 20px;
  ul li{
    padding-left: 5px;
  }
  ul li:first-child{
    padding-left: 0;
  }
}
.react-multi-carousel-list {
  max-width: 100%;
}
.selected-video-player {
  width: 100% !important;
  height: 100% !important;
  min-height: 100%;
  video {
    object-fit: cover;
  }
}