.information-section{
  .information {
    .information-octagon {
      margin-top: -75px;
      width: 130px;
    }
    .information-content {
      padding: 50px 40px 95px 70px;
      .information-text {
      }
      .information-help {
        font-size: 19px;
      }
    }
  }
  @media (min-width: 769px) and (max-width: 1120px) {
    .information {
      .information-content {
        padding: 15px 40px 60px 70px;
        .information-text {
          font-size: 19px;
        }
        .information-help {
          font-size: 15px;
          p {
            padding-left: 10px;
            margin-right: 13px;
          }
          .information-imgbtn {
            width: 50px !important;
            height: 50px !important;
            p {
              font-size: 16px ;
              padding-left: 15px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .information {
      .information-octagon {
        margin-top: -44px;
        width: 75px;
      }
      .information-content {
        padding: 30px 40px 40px 70px;
        .information-help {
          font-size: 19px;
          padding-left: 50px;
        }
      }
    }
  }
  @media (min-width: 426px) and (max-width: 513px) {
    .information-help {
      font-size: 19px;
    }
  }

  @media (max-width: 425px) {
    .information {
      padding-bottom: 37px;
      .information-content {
        padding: 20px 20px 5px 20px;
        .information-help {
          font-size: 15px;
          justify-content: center;
        }
      }
    }
  }
}