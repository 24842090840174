.donate-form {
  width: 100%;
  padding-top: 20px !important;
}

.subscribe-form {
  margin-bottom: 55px;
  min-height: 400px;
  width: 100%;
}
